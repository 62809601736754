import React, { useEffect, useState, useRef } from "react"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import utils from "../../utils/utils"
import Pagination from "../../components/pagination/pagination"
import "./index.scss"
import { useStaticQuery, graphql } from "gatsby"
import tracking from "../../utils/tracking"
import links from "../../utils/links"

const index = () => {
  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])
  const data = useStaticQuery(graphql`
    query {
      nodePageFaq {
        field_seo_description
        field_seo_titre
        field_titre_page_sur_hero {
          value
        }
      }
      allNodeQuestionFaq {
        edges {
          node {
            field_order
            title
            relationships {
              field_category {
                name
                id
                weight
              }
            }
            body {
              value
            }
          }
        }
      }
    }
  `)

  const dataFormated = () => {
    let result = []
    if (data) {
      if (data.hasOwnProperty("allNodeQuestionFaq")) {
        const { edges } = data.allNodeQuestionFaq
        if (edges) {
          edges.forEach(element => {
            const { node } = element
            if (node) {
              /** TAXONOMIE */
              const { id } = node.relationships.field_category
              const name_taxo = node.relationships.field_category.name
              const { value } = node.body
              const { title } = node
              const { field_order } = node
              if (id && name_taxo) {
                /** INIT RESULT */
                const indexByIdTaxo = result.findIndex(el => el.id === id)
                const createRow = {
                  title: name_taxo,
                  question: title,
                  response: value,
                  order: field_order,
                }
                /** create */
                if (indexByIdTaxo === -1) {
                  result.push({ id: id, label: name_taxo, data: [createRow] })
                }
                /** IS INCLUDE */
                if (indexByIdTaxo !== -1) {
                  result[indexByIdTaxo].data.push(createRow)
                }
              }
            }
          })
        }
        if (result.length > 0) {
          result.forEach(elm => {
            elm.data.sort((a, b) => (a.order > b.order ? 1 : -1))
          })
        }
      }
    }
    return result
  }

  const targetScroll = useRef(null)
  const itemsPerPage = 8
  const dataList = dataFormated()
  const [openNav, setOpenNav] = useState(false)
  const [selected, setSelected] = useState(null)
  const [lineSelected, setLineSelected] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState(null)
  const seoPage = {
    title: data.nodePageFaq.field_seo_titre,
    description: data.nodePageFaq.field_seo_description,
  }
  const createItem = (el, index) => {
    return (
      <>
        <button
          key={index}
          onClick={() => setLineSelected(lineSelected == index ? null : index)}
          className="btn-no-focus d-f ai-c jc-sb w-100 px-0"
        >
          <div className="d-f fd-c ai-fs">
            {selected === null && (
              <h2 className="c-1 fs-16 lh-24 mb-1">{el.title}</h2>
            )}
            <p className="cg-7 fs-16 lh-24 ta-l">{el.question}</p>
          </div>
          <span className="icon-arrow-down-c1 target-icon"></span>
        </button>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: el.response }}
        ></div>
      </>
    )
  }

  const allData = (dataToTransform = dataList) => {
    let result = []
    if (dataToTransform) {
      dataToTransform.forEach(el => {
        el.data.forEach(elm => {
          result.push(elm)
        })
      })
    }
    return result
  }
  const selectCategory = index => {
    setSelected(index)
    setCurrentPage(1)
    setLineSelected(null)
  }
  const handleChangePage = (page: any) => {
    if (page) {
      setCurrentPage(page)
    }
  }
  useEffect(() => {
    /** Tracking */
    tracking.track("typeTemplate", "faqpage")
    tracking.track("page_name", seoPage.title + (isTherecampagne ? "/" + campagne : ""))
    tracking.track("categorie", "telesurveillance")
    tracking.trackInit()
  }, [])
  useEffect(() => {
    if (dataList) {
      const dataToFormat =
        selected === null
          ? allData(dataFormated()).length
          : dataList[selected].data.length
      setPagination(utils.pagination(dataToFormat, currentPage, itemsPerPage))
      if (targetScroll.current) {
        window.scrollTo({
          top: targetScroll.current.offsetTop,
          behavior: "smooth",
        })
      }
    }
  }, [selected, currentPage])

  return (
    <Layout isViewPromoOnHeader={true}>
      <Seo
        title={seoPage.title}
        description={seoPage.description}
        linkCanonical={"faq/"}
      />
      <div className="block-page-faq">
        <div className="bc-2 d-f jc-c ai-c py-6 px-md-3 px-md-3">
          <div
            className="title-on-hero"
            dangerouslySetInnerHTML={{
              __html: data.nodePageFaq.field_titre_page_sur_hero.value,
            }}
          ></div>
        </div>
        <article className="wrapper container-page-faq d-f ai-fs fd-r fd-md-c">
          {/*** NAV ASIDE */}

          <aside className="aside-nav-faq pr-3 pr-md-0">
            <label className="label-current-select br-12 d-n d-md-f p-r bcg-2">
              <input
                type="text"
                readOnly
                className="input-hide-toggle"
                onClick={() => setOpenNav(openNav ? false : true)}
                onBlur={() =>
                  setTimeout(() => {
                    setOpenNav(false)
                  }, 200)
                }
              />
              <p className="d-f ai-c jc-sb mb-0 w-100 ff-gbook">
                {selected === null
                  ? "Toutes les questions"
                  : dataList[selected].label}
                <span className="icon-arrow-down-c1 d-b"></span>
              </p>
            </label>
            <ul
              className={
                openNav
                  ? "ul d-f fd-c is-active nav-category"
                  : "nav-category ul d-f fd-c"
              }
            >
              <li
                onClick={() => selectCategory(null)}
                className={
                  selected === null
                    ? "ls-n item mb-5 is-active"
                    : "ls-n item mb-5"
                }
              >
                <button className="btn-hover-underline ta-l">
                  Toutes les questions
                </button>
              </li>
              {dataList.map((el, index) => (
                <li
                  key={index}
                  onClick={() => selectCategory(index)}
                  className={
                    selected === index
                      ? "ls-n mb-5 item is-active"
                      : "ls-n mb-5 item"
                  }
                >
                  <button className="btn-hover-underline ta-l ">
                    {el.label}
                  </button>
                </li>
              ))}
            </ul>
          </aside>

          {/*** LIST FAQ */}
          <section ref={targetScroll} className="list-faq">
            {dataList && (
              <ul className="ul">
                {selected === null ? (
                  <>
                    {allData()
                      .slice(
                        itemsPerPage * currentPage - itemsPerPage,
                        itemsPerPage * currentPage
                      )
                      .map((elmt_child, index) => (
                        <li
                          key={index}
                          className={
                            lineSelected === index
                              ? "ls-n is-active item"
                              : "ls-n item"
                          }
                        >
                          {createItem(elmt_child, index)}
                        </li>
                      ))}
                  </>
                ) : (
                  <>
                    {dataList[selected].data
                      .slice(
                        itemsPerPage * currentPage - itemsPerPage,
                        itemsPerPage * currentPage
                      )
                      .map((el, index) => (
                        <li
                          key={index}
                          className={
                            lineSelected === index
                              ? "ls-n is-active item"
                              : "ls-n item"
                          }
                        >
                          {createItem(el, index)}
                        </li>
                      ))}
                  </>
                )}
              </ul>
            )}
            {/** PAGINATION */}
            <div className="py-3">
              {pagination && (
                <Pagination
                  data={pagination}
                  handleChange={handleChangePage}
                  addClass={"jc-fe"}
                />
              )}
            </div>
          </section>
        </article>
      </div>
    </Layout>
  )
}

export default index
